/// <reference path="../santa.d.ts" />
import * as _ from 'lodash'
import { DIALOG_KEY } from '../constants/dialogs'
import { BI } from '../bi'
import { CollectionExposure } from '../site-members-aspect/dialogs/dialogs-types'
import { VIEWER_COOKIES } from '../constants/misc'

const GLOAL_DATA_ASPECT_KEY = 'siteMembers'

const buildAspectState = (aspectSiteApi: Santa.AspectSiteAPI): ViewerBoundAspectState => ({
  read(path: string) {
    const data = aspectSiteApi.getAspectGlobalData(GLOAL_DATA_ASPECT_KEY)
    return path ? _.get(data, path) : data
  },
  update(newData: any) {
    aspectSiteApi.updateAspectGlobalData(GLOAL_DATA_ASPECT_KEY, newData)
  },
  delete(path: string) {
    const newData = _.set(aspectSiteApi.getAspectGlobalData(GLOAL_DATA_ASPECT_KEY), path, undefined)
    aspectSiteApi.updateAspectGlobalData(GLOAL_DATA_ASPECT_KEY, newData)
  }
})

export interface ViewerBoundAspectState {
  read(path: string): any
  update(data: Object): void
  delete(path: string): void
}
export interface SiteState {
  aspectSiteAPI: Santa.AspectSiteAPI
  experiment: Santa.Experiment
  /**
   * FIXME ☢️ ️⚠️ must be refactored into AspectState (a nasty legacy code).
   * NOTE - only after all viewer's SiteMembersAspect usages of it have been moved into here
   */
  privateMembers: Santa.PrivateMembers
  onLoginSuccessCallbacks: Santa.OnLoginSuccessCallbacks
  downloadCompsUtils: Santa.DownloadCompsUtils
}

export interface ExternalLibs {
  errorPages: {
    isErrorPage(pageId: string): boolean
  }
  pmrpc: any
  coreUtils: {
    translationsLoader: any
    cookieUtils: any
    hashUtils: any
    linkRenderer: any
  }
  urlUtils: any
  ajaxLibrary: any
}

export interface Constants {
  biEvents: Object
}

export default class ViewerRuntime {
  private readonly bi: BI
  private readonly _siteData: Santa.SiteData
  private readonly _aspectSiteAPI: Santa.AspectSiteAPI
  private readonly _externals: ExternalLibs
  private readonly _experiment: Santa.Experiment
  private readonly _privateMembers: Santa.PrivateMembers
  private readonly _onLoginSuccessCallbacks: Santa.OnLoginSuccessCallbacks
  private readonly _downloadCompsUtils: Santa.DownloadCompsUtils
  private readonly _viewerGlobalAspectState: ViewerBoundAspectState

  constructor({
    aspectSiteAPI,
    experiment,
    privateMembers,
    onLoginSuccessCallbacks,
    downloadCompsUtils,
    externals,
    constants
  }: {
    aspectSiteAPI: Santa.AspectSiteAPI
    experiment: Santa.Experiment
    privateMembers: Santa.PrivateMembers
    onLoginSuccessCallbacks: Santa.OnLoginSuccessCallbacks
    downloadCompsUtils: Santa.DownloadCompsUtils
    externals: ExternalLibs
    constants: Constants
  }) {
    this._aspectSiteAPI = aspectSiteAPI
    this._siteData = this._aspectSiteAPI.getSiteData()
    this._externals = externals
    this._experiment = experiment
    this._privateMembers = privateMembers
    this.bi = new BI(constants.biEvents, {
      visitorId: aspectSiteAPI.getSiteAPI().getBiVisitorId(),
      reportBI: aspectSiteAPI.reportBI
    })
    this._viewerGlobalAspectState = buildAspectState(this._aspectSiteAPI)
    this._onLoginSuccessCallbacks = onLoginSuccessCallbacks
    this._downloadCompsUtils = downloadCompsUtils
  }

  private _getSmSettingsObject(path: string[] = []) {
    const siteStructureData = this._siteData.getDataByQuery('masterPage')
    return _.get(siteStructureData, ['smSettings'].concat(path))
  }

  private _getSiteMemersClientSpecMap(key?: string) {
    return _.get(
      this._siteData.getClientSpecMapEntriesByType('sitemembers'),
      ['0'].concat(key || [])
    )
  }

  get BI(): BI {
    return this.bi
  }

  get siteMember(): any {
    return this._privateMembers[this.getSiteId()].siteMember
  }

  get approvedPasswordPages(): string[] {
    return this._privateMembers[this.getSiteId()].approvedPasswordPages
  }

  get translationService() {
    return (key: string, lang: string) =>
      this._externals.coreUtils.translationsLoader.getTranslation(
        'siteMembersTranslations',
        lang,
        key
      )
  }

  get rendererModel() {
    return this._siteData.rendererModel
  }

  get serviceTopology() {
    return this._siteData.serviceTopology
  }

  getViewerGlobalAspectState() {
    return this._viewerGlobalAspectState
  }

  getMetaSiteId(): string {
    return this._siteData.getMetaSiteId()
  }

  getPublicBaseUrl(): string {
    return this._siteData.getPublicBaseUrl()
  }

  isPageExists(pageId: string) {
    return (
      (pageId && _.includes(this._siteData.getAllPageIds(), pageId)) ||
      this._externals.errorPages.isErrorPage(pageId)
    )
  }

  getCustomSignupPageId() {
    return this._getSmSettingsObject(['customSignUpPageId']) || null
  }

  isCustomSignupPageExists() {
    return this.isPageExists(this.getCustomSignupPageId())
  }

  getDialogToShowFirst() {
    return this._getSmSettingsObject(['smFirstDialogLogin']) ? DIALOG_KEY.Login : DIALOG_KEY.SignUp
  }

  getCustomNoPermissionsPageId() {
    return this._getSmSettingsObject(['customNoPermissionsPageId']) || null
  }

  navigateToPage(pageId: string) {
    return this._aspectSiteAPI.navigateToPage({ pageId })
  }

  isSocialLoginEnabled(): boolean {
    const settings = this._getSmSettingsObject() || {}
    return Boolean(settings.socialLoginGoogleEnabled || settings.socialLoginFacebookEnabled)
  }

  isMobileView(): boolean {
    return this._siteData.isMobileView()
  }

  isExperimentOpen(key: string): boolean {
    return this._experiment.isOpen(key, this._siteData)
  }

  getCurrentUrl() {
    return this._siteData.currentUrl
  }

  getSmCollectionExposure(): CollectionExposure {
    return this._getSiteMemersClientSpecMap('collectionExposure')
  }

  getSmCollectionId(): string {
    return this._getSiteMemersClientSpecMap('smcollectionId')
  }

  getSmCollectionType(): string {
    return this._getSiteMemersClientSpecMap('collectionType')
  }

  getPrimaryPageId() {
    return this._siteData.getPrimaryPageId()
  }

  getMainPagePath(): any {
    return this._siteData.getMainPagePath()
  }

  getMainPageId(): string {
    return this._siteData.getMainPageId()
  }

  renderLink(linkData: {
    pageId: {
      id: string
    }
    type: string
    queryParams: Object
  }): any {
    return this._externals.coreUtils.linkRenderer.renderLink(
      linkData,
      this._siteData,
      this._siteData.getRootNavigationInfo()
    )
  }

  changeHref(href: string): any {
    this._aspectSiteAPI.getSiteAPI().href(href)
  }

  async getAppAPI(msg: { appDefId: string; workerId: string }) {
    const PLATFORM_PUBLIC_API_PREFIX = 'viewer_platform_public_api_'
    const handler = await this._aspectSiteAPI
      .getSiteAPI()
      .getSiteAspect('WidgetAspect')
      .getWidgetHandler()
    const apiName = `${PLATFORM_PUBLIC_API_PREFIX + msg.appDefId}_${msg.workerId}`
    const postMessageTarget = handler && handler.getPostMessageTarget(msg.workerId)
    if (postMessageTarget) {
      return this._externals.pmrpc.api.request(apiName, { target: postMessageTarget })
    }
    throw new Error('Application API not found.')
  }

  getSiteId(): string {
    return this._siteData.siteId
  }

  getCookie(key: string): string {
    return this._externals.coreUtils.cookieUtils.getCookie(key)
  }

  setCookie(
    cookieName: string,
    value: string,
    time: number | string | Date,
    domain: string,
    path: string,
    secure: boolean
  ): void {
    this._externals.coreUtils.cookieUtils.setCookie(cookieName, value, time, domain, path, secure)
  }

  deleteCookie(cookieKey: string, hostname: string, path: string): void {
    this._externals.coreUtils.cookieUtils.deleteCookie(cookieKey, hostname, path)
  }

  ajax(ajaxObj: any) {
    return this._externals.ajaxLibrary.ajax(ajaxObj)
  }

  reloadPage() {
    this._aspectSiteAPI.getSiteAPI().reloadPage()
  }

  async updateWixCodeModelDataAfterLogin(): Promise<void> {
    return this._aspectSiteAPI
      .getSiteAPI()
      .getSiteAspect('WidgetAspect')
      .updateWixCodeModelDataAfterLogin()
  }

  async reloadClientSpecMap(callback: () => void): Promise<void> {
    const dynamicClientSpecMapAspect = await this._aspectSiteAPI
      .getSiteAPI()
      .getSiteAspect('dynamicClientSpecMap')
    return dynamicClientSpecMapAspect.reloadClientSpecMap(callback)
  }

  encryptPassword(givenPassword: string): string {
    return this._externals.coreUtils.hashUtils.SHA256.b64_sha256
  }

  runActionImmediately(callback: () => void): void {
    this._aspectSiteAPI.getActionQueue().runImmediately(callback)
  }

  getSMToken(): string {
    return this._siteData.getSMToken()
  }

  invokeOnLoginSuccessCallbacks(): void {
    _.invokeMap(this._onLoginSuccessCallbacks, 'callback')
  }

  getCurrentUrlPageId(): string {
    return this._siteData.getCurrentUrlPageId()
  }

  downloadAllComps(): void {
    this._downloadCompsUtils.downloadAllComps()
  }

  waitForCompsDownload(callback: () => void): void {
    this._downloadCompsUtils.waitForCompsDownload(callback)
  }

  setSMSessionCookie(cookieData: any) {
    const cookieName = cookieData.cookieName || VIEWER_COOKIES.SM_SESSION
    const sessionToken = cookieData.sessionToken
    const expirationDate = cookieData.rememberMe ? cookieData.expirationDate : 0
    this.setCookie(
      cookieName,
      sessionToken,
      expirationDate,
      this.getCurrentUrl().hostname,
      this.getMainPagePath(),
      false
    )
  }

  setPagesJsonFileName(pages: string[]): any {
    this._siteData.setPagesJsonFileName(pages)
  }

  getBiVisitorId(): string {
    return this._aspectSiteAPI.getSiteAPI().getBiVisitorId()
  }
}

export enum DIALOG_KEY {
  Login = 'login',
  SignUp = 'register',
  ResetPasswordEmail = 'resetPasswordEmail',
  ResetPasswordNewPassword = 'resetPasswordNewPassword',
  Notification = 'notification',
  Credits = 'credits',
  PasswordProtected = 'enterPassword',
  EmailVerification = 'emailVerification',
  SentConfirmationEmail = 'sentConfirmationEmail',
  Welcome = 'welcome',
  NoPermissionsToPage = 'noPermissionsToPage',
  AdminLogin = 'AdminLogin' // FIXME - this did NOT exists in santa!!
}

export enum NOTIFICATION_KEY {
  SiteOwner = 'siteowner',
  SignUp = 'register',
  ResetPasswordEmail = 'resetPasswordEmail',
  ResetPasswordNewPassword = 'resetPasswordNewPassword'
}

export const AUTH_DIALOGS = [DIALOG_KEY.Login, DIALOG_KEY.SignUp]

export const DIALOGS_SKINS = {
  Login: {
    themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.memberLoginDialogSkinNew',
    socialMobileThemeStyled:
      'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.memberLoginDialogSkinSocialMobile',
    themeStyledMaterial:
      'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.memberLoginDialogSkinMaterial',
    socialMobileThemeStyledMaterial:
      'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.memberLoginDialogSkinMaterial'
  },
  SignUp: {
    themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.signUpDialogSkinNew',
    socialMobileThemeStyled:
      'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.signUpDialogSkinSocialMobile',
    themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.signUpDialogSkinMaterial',
    socialMobileThemeStyledMaterial:
      'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.signUpDialogSkinMaterial'
  },
  ResetPasswordEmail: {
    themeStyled:
      'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.requestPasswordResetDialogSkinNew',
    themeStyledMaterial:
      'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.requestPasswordResetDialogSkinMaterial'
  },
  ResetPasswordNewPassword: {
    themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.resetPasswordDialogSkinNew',
    themeStyledMaterial:
      'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.resetPasswordDialogSkinMaterial'
  },
  Notification: {
    themeStyled: 'wysiwyg.viewer.skins.dialogs.notificationDialogSkinNew',
    themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.notificationDialogSkinMaterial'
  },
  PasswordProtected: {
    themeStyled: 'wysiwyg.viewer.skins.dialogs.enterPasswordDialogSkinNew',
    themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.enterPasswordDialogSkinMaterial'
  },
  EmailVerification: {
    themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.emailVerificationDialogSkinNew',
    fixedStyle: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.emailVerificationDialogSkinFixed',
    themeStyledMaterial:
      'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.emailVerificationDialogSkinMaterial'
  },
  SentConfirmationEmail: {
    themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.sentConfirmationEmail',
    themeStyledMaterial:
      'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.sentConfirmationEmailMaterial'
  },
  Welcome: {
    themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.welcomeDialogSkinNew',
    fixedStyle: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.welcomeDialogSkinFixed',
    themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.welcomeDialogSkinMaterial'
  },
  NoPermissionsToPage: {
    themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.noPermissionsToPage',
    themeStyledMaterial:
      'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.noPermissionsToPageMaterial'
  }
}

export const DEFAULT_DIALOG_LANG = 'en'

export enum PRIVACY_STATUS {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

import { DIALOG_KEY, DIALOGS_SKINS } from '../../../constants/dialogs'
import ViewerRuntime from '../../../viewer-runtime'

function getStructure(compType: string, id: string, skin: string): Santa.ComponentStructure {
  return {
    componentType: compType,
    type: 'Component',
    id,
    key: id,
    skin
  }
}

function getSkin(skins: any, runtime: ViewerRuntime): string {
  const isSocialMobile =
    runtime.isSocialLoginEnabled() && runtime.isMobileView() && skins.socialMobileThemeStyled
  return skins[
    `${isSocialMobile ? 'socialMobileThemeStyled' : 'themeStyled'}${
      runtime.isExperimentOpen('newLoginTheme') ? 'Material' : ''
    }`
  ]
}

function getNotificationStructure() {
  return {
    componentId: 'wysiwyg.viewer.components.dialogs.NotificationDialog',
    dialogId: 'notificationDialog',
    dialogSkins: DIALOGS_SKINS.Notification
  }
}

function getLoginStructure() {
  return {
    componentId: 'wysiwyg.viewer.components.dialogs.siteMemberDialogs.MemberLoginDialog',
    dialogId: 'memberLoginDialog',
    dialogSkins: DIALOGS_SKINS.Login
  }
}

function getSignUpStructure() {
  return {
    componentId: 'wysiwyg.viewer.components.dialogs.siteMemberDialogs.SignUpDialog',
    dialogId: 'signUpDialog',
    dialogSkins: DIALOGS_SKINS.SignUp
  }
}

function getResetPasswordEmailStructure() {
  return {
    componentId: 'wysiwyg.viewer.components.dialogs.siteMemberDialogs.RequestPasswordResetDialog',
    dialogId: 'requestResetPasswordDialog',
    dialogSkins: DIALOGS_SKINS.ResetPasswordEmail
  }
}

function getResetPasswordNewPasswordStructure() {
  return {
    componentId: 'wysiwyg.viewer.components.dialogs.siteMemberDialogs.ResetPasswordDialog',
    dialogId: 'resetPasswordDialog',
    dialogSkins: DIALOGS_SKINS.ResetPasswordNewPassword
  }
}

function getEnterPasswordStructure() {
  return {
    componentId: 'wysiwyg.viewer.components.dialogs.EnterPasswordDialog',
    dialogId: 'enterPasswordDialog',
    dialogSkins: DIALOGS_SKINS.PasswordProtected
  }
}

function getEmailVerificationStructure() {
  return {
    componentId: 'wysiwyg.viewer.components.dialogs.siteMemberDialogs.EmailVerificationDialog',
    dialogId: 'emailVerificationDialog',
    dialogSkins: DIALOGS_SKINS.EmailVerification
  }
}

function getSentConfirmationEmailStructure() {
  return {
    componentId: 'wysiwyg.viewer.components.dialogs.siteMemberDialogs.SentConfirmationEmailDialog',
    dialogId: 'sentConfirmationEmailDialog',
    dialogSkins: DIALOGS_SKINS.SentConfirmationEmail
  }
}

function getWelcomeStructure() {
  return {
    componentId: 'wysiwyg.viewer.components.dialogs.siteMemberDialogs.WelcomeDialog',
    dialogId: 'welcomeDialog',
    dialogSkins: DIALOGS_SKINS.Welcome
  }
}

function getNoPermissionsToPageStructure() {
  return {
    componentId: 'wysiwyg.viewer.components.dialogs.siteMemberDialogs.NoPermissionsToPageDialog',
    dialogId: 'noPermissionsToPageDialog',
    dialogSkins: DIALOGS_SKINS.NoPermissionsToPage
  }
}

interface DialogStructureConfig {
  componentId: string
  dialogId: string
  dialogSkins: Object
}

function getStructureConfigByDialog(dialogToDisplay: DIALOG_KEY): DialogStructureConfig {
  switch (dialogToDisplay) {
    case DIALOG_KEY.Login:
      return getLoginStructure()
    case DIALOG_KEY.SignUp:
      return getSignUpStructure()
    case DIALOG_KEY.ResetPasswordEmail:
      return getResetPasswordEmailStructure()
    case DIALOG_KEY.ResetPasswordNewPassword:
      return getResetPasswordNewPasswordStructure()
    case DIALOG_KEY.Notification:
      return getNotificationStructure()
    case DIALOG_KEY.PasswordProtected:
      return getEnterPasswordStructure()
    case DIALOG_KEY.EmailVerification:
      return getEmailVerificationStructure()
    case DIALOG_KEY.SentConfirmationEmail:
      return getSentConfirmationEmailStructure()
    case DIALOG_KEY.Welcome:
      return getWelcomeStructure()
    case DIALOG_KEY.NoPermissionsToPage:
      return getNoPermissionsToPageStructure()
    case DIALOG_KEY.AdminLogin:
    default:
      return null
  }
}

function getCurrentDialogComponentStructure(runtime: ViewerRuntime, dialogToDisplay: DIALOG_KEY) {
  const { componentId, dialogId, dialogSkins } = getStructureConfigByDialog(dialogToDisplay)
  return getStructure(componentId, dialogId, getSkin(dialogSkins, runtime))
}

export { getCurrentDialogComponentStructure }

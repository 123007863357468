import * as _ from 'lodash'
import { getCurrentDialogComponentStructure } from './dialogs/structures/structure-factory'
import PropsFactory from './dialogs/props/props-factory'
import { DIALOG_KEY, NOTIFICATION_KEY } from '../constants/dialogs'
import {
  ShowAuthenticationDialogOptions,
  ShowResetPasswordDialogOptions,
  ShowDialogConfig,
  LogoutOptions
} from './dto'
import { IAspectState, IHandlersFactory } from './aspect-types'
import ViewerRuntime, { ViewerBoundAspectState } from '../viewer-runtime'
import { VIEWER_COOKIES, APPS, SECTION } from '../constants/misc'
import SiteMembersBackendService from '../site-members-backend-service'
import {
  Dialog,
  EnterPasswordSubmitCallback,
  ResetPasswordRequestSubmitCallback,
  SignupSubmitCallback,
  PureHandler,
  OnTokenMessage,
  ResendEmailCallback,
  LoginSubmitCallback,
  ResetPasswordCallback
} from './dialogs/dialogs-types'

class AspectState implements IAspectState {
  private readonly _s: ViewerBoundAspectState

  constructor(runtime: ViewerRuntime) {
    this._s = runtime.getViewerGlobalAspectState()
  }

  get smtoken(): string {
    return this._s.read('smtoken')
  }

  get pendingMemberId(): string {
    return this._s.read('pendingMemberId')
  }

  set pendingMemberId(pendingMemberId: string) {
    this._s.update({ pendingMemberId })
  }

  get notClosable(): boolean {
    return this._s.read('notClosable')
  }

  get nextPageInfo() {
    return this._s.read('nextPageInfo')
  }

  get stopShowDialog(): boolean {
    return this._s.read('stopShowDialog')
  }

  set stopShowDialog(shouldStop: boolean) {
    this._s.update({ stopShowDialog: shouldStop })
  }

  get dialogToDisplay(): DIALOG_KEY {
    return this._s.read('dialogToDisplay')
  }

  get languageToDisplay(): string {
    return this._s.read('languageToDisplay')
  }

  get checkCommunityCheckbox(): boolean {
    return this._s.read('checkCommunityCheckbox')
  }

  get showDialogMessage(): boolean {
    return this._s.read('showDialogMessage')
  }

  set initiatorAppId(appId: string) {
    this._s.update({ initiatorAppId: appId })
  }

  get notificationToDisplay() {
    return this._s.read('notificationToDisplay')
  }

  set notificationToDisplay(notificationToDisplay: NOTIFICATION_KEY) {
    this._s.update({ notificationToDisplay })
  }

  set logoutState(isLogout: boolean) {
    this._s.update({ logoutState: isLogout })
  }

  set passwordDigest(passwordDigest: string) {
    this._s.update({ passwordDigest })
  }

  get onCancelCallback() {
    return this._s.read('onCancelCallback')
  }

  get invokeResetPasswordDialogCb() {
    return this._s.read('invokeResetPasswordDialogCb')
  }

  set invokeResetPasswordDialogCb(shouldInvoke: boolean) {
    if (_.isUndefined(shouldInvoke) || _.isNull(shouldInvoke)) {
      this._s.delete('invokeResetPasswordDialogCb')
      return
    }
    this._s.update({ invokeResetPasswordDialogCb: shouldInvoke })
  }

  set navigatingOutPageId(pageId: string) {
    this._s.update({ navigatingOutPageId: pageId })
  }

  get dialogProcessSuccessCallback() {
    return this._s.read('dialogProcessSuccessCallback')
  }

  set dialogProcessSuccessCallback(dialogProcessSuccessCallback: () => void) {
    this._s.update({ dialogProcessSuccessCallback })
  }

  set memberDetails(details: Object) {
    this._s.update({ memberDetails: details })
  }

  showDialog({
    dialogType,
    successCallback,
    language,
    cancelCallback,
    checkCommunityCheckbox
  }: ShowDialogConfig) {
    this._s.update(
      _.merge(
        { dialogToDisplay: dialogType },
        language ? { languageToDisplay: language } : {},
        _.isFunction(successCallback) ? { dialogProcessSuccessCallback: successCallback } : {},
        _.isFunction(cancelCallback) ? { onCancelCallback: cancelCallback } : {},
        _.isBoolean(checkCommunityCheckbox) ? { checkCommunityCheckbox } : {},
        { logoutState: false }
      )
    )
  }

  invokeResetPasswordDialogCallback() {
    this._s.update({ invokeResetPasswordDialogCb: true })
  }

  forceStopShowDialog(): void {
    if (this.stopShowDialog !== true) {
      this.stopShowDialog = true
    }
  }

  showNotification(notificationType: NOTIFICATION_KEY, language: string): void {
    this._s.update(
      _.merge(
        {},
        {
          notificationToDisplay: notificationType,
          dialogToDisplay: DIALOG_KEY.Notification
        },
        language ? { languageToDisplay: language } : {}
      )
    )
  }

  batchUpdate(data: Partial<IAspectState>) {
    this._s.update(data)
  }
}

class HandlersFactory implements IHandlersFactory {
  private readonly core: SiteMembersAspectCore
  private readonly runtime: ViewerRuntime
  private readonly state: AspectState
  private readonly backendService: SiteMembersBackendService

  constructor(core: SiteMembersAspectCore) {
    const { runtime, aspectState, backendService } = core
    this.core = core
    this.runtime = runtime
    this.state = aspectState
    this.backendService = backendService
  }

  private _isPageProtectedByPasswordOnServer(pageId: string) {
    return _.includes(this.core.runtime.rendererModel.passwordProtectedPages, pageId)
  }

  private _closeDialog(
    dialog: Dialog,
    authSuccess: boolean,
    isUserAction?: boolean,
    nextPageInfo?: Santa.NextPageInfo
  ) {
    if (this.runtime.getCurrentUrl().query.showSignUpDialog) {
      // FIXME ⚠️ - this line is not really valid!!!
      // delete this.siteData.currentUrl.query.showSignUpDialog
      const linkData = {
        pageId: {
          id: this.runtime.getMainPageId()
        },
        type: 'PageLink',
        queryParams: this.runtime.getCurrentUrl().query
      }
      const link = this.runtime.renderLink(linkData)
      this.runtime.changeHref(link.href)
      return
    }
    if (isUserAction) {
      this.runtime.BI.reportExitDialog({
        context: dialog.props.biContext || dialog.props.id
      })
    }

    const siteMember = this.runtime.siteMember
    const onCancelCallback = this.state.onCancelCallback
    if (_.isFunction(onCancelCallback) && !siteMember.details) {
      onCancelCallback()
    }

    dialog.performCloseDialog(() => {
      this.runtime.runActionImmediately(() => {
        this.state.batchUpdate({
          dialogToDisplay: null,
          onCancelCallback: null,
          dialogProcessSuccessCallback: null,
          showDialogMessage: false,
          smtoken: this.runtime.getSMToken()
        })
        this.state.invokeResetPasswordDialogCb = null
        const nextPage = nextPageInfo || this.state.nextPageInfo
        dialog.endLoader()
        if (authSuccess) {
          if (nextPage) {
            this.runtime.invokeOnLoginSuccessCallbacks()
            if (nextPage.routerDefinition) {
              nextPage.isRefetchingPageAfterLogin = true
              delete nextPage.pageId
            }
            this.runtime.navigateToPage(nextPage.pageId)
          }
        } else if (nextPage && nextPage.pageId === this.runtime.getCurrentUrlPageId()) {
          this.state.navigatingOutPageId = nextPage.pageId
          this.runtime.navigateToPage(this.runtime.getMainPageId())
        }
      })
    })
  }

  private _validatePasswordOnServer(
    password: string,
    nextPageInfo: Santa.NextPageInfo,
    onSuccess: (response: any) => void,
    onError: (err: any) => void
  ) {
    const url = this.runtime.serviceTopology.protectedPageResolverUrl
    const payload = {
      password,
      metaSiteId: this.runtime.getMetaSiteId(),
      siteId: this.runtime.getSiteId(),
      pageId: nextPageInfo.pageId
    }
    this.runtime.ajax({
      type: 'POST',
      url,
      data: payload,
      dataType: 'json',
      cache: false,
      contentType: 'application/json',
      success: onSuccess,
      error: onError
    })
  }

  private _validatePasswordAgainstJSON(dialog: Dialog, givenPassword: string, onError: () => void) {
    const givenPasswordEncypted = this.runtime.encryptPassword(givenPassword)
    if (givenPasswordEncypted === this.state.passwordDigest) {
      this._closeDialogAndNavigateToPage(dialog, this.state.nextPageInfo)
    } else {
      onError()
    }
  }

  private _closeDialogAndNavigateToPage(dialog: Dialog, nextPage: Santa.NextPageInfo) {
    this._closeDialog(dialog, true)
    this.runtime.approvedPasswordPages.push(nextPage.pageId)
    this.runtime.navigateToPage(nextPage.pageId)
    this.state.passwordDigest = null
  }

  private _isEmailVerificationRequired(data: any) {
    return (
      data.payload.hasOwnProperty('sessionToken') &&
      data.payload.sessionToken === null &&
      !data.payload.emailVerified
    )
  }

  private _onClientSpecMapReloaded(dialog: Dialog, callbackData: any, biEvent?: any) {
    dialog.endLoader()
    const siteMember = this.runtime.siteMember
    siteMember.details = callbackData.payload.siteMemberDto
    this.state.memberDetails = siteMember.details

    const successCb = this.state.dialogProcessSuccessCallback
    if (_.isFunction(successCb)) {
      successCb(siteMember.details)
      this.state.dialogProcessSuccessCallback = null
    }

    let nextPageInfo
    if (callbackData.payload.pages) {
      this.runtime.setPagesJsonFileName(callbackData.payload.pages)
      nextPageInfo = this.state.nextPageInfo
    }
    this._closeDialog(dialog, true, true, nextPageInfo)
    // if (biEvent) {
    //   // ⚠️ implement only for SITE_MEMBER_LOGIN_SUCCESS
    //   const userName =
    //     (siteMember.details.attribute && siteMember.details.attribute.name) ||
    //     siteMember.details.email // eslint-disable-line no-mixed-operators
    //   this.aspectSiteApi.reportBI(biEvent, {
    //     userName: utils.logger.sanitizePIIForBi(userName)
    //   })
    // }
  }

  private _handleSmSession(dialog: Dialog, callbackData: any, biEvent?: any) {
    this.runtime.waitForCompsDownload(() => {
      if (this._isEmailVerificationRequired(callbackData)) {
        this.state.pendingMemberId = callbackData.payload.siteMemberDto.id
        this.state.showDialog({
          dialogType: DIALOG_KEY.EmailVerification,
          language: dialog.props.language,
          cancelCallback: () => {
            this.state.pendingMemberId = null
          }
        })
      } else {
        if (!callbackData.httpOnlySession) {
          this.runtime.setSMSessionCookie(callbackData.payload)
        }
        this.core.reloadClientSpecMap(() =>
          this._onClientSpecMapReloaded(dialog, callbackData, biEvent)
        )
      }
    })
  }

  private _onApplyRegisterSuccess(language: string, registerData: any) {
    const siteMember = this.runtime.siteMember
    siteMember.details = registerData.payload
    const successCb = this.state.dialogProcessSuccessCallback
    if (_.isFunction(successCb)) {
      successCb(siteMember.details)
      this.state.dialogProcessSuccessCallback = null
    }
    this.state.showNotification(NOTIFICATION_KEY.SignUp, language)
  }

  private _resendEmailVerification(pendingMemberId: string) {
    this.backendService.resendEmailVerification(pendingMemberId, _.noop, _.noop)
  }

  private _revokeAppPermissions(postMessageTarget: any, type: string) {
    postMessageTarget.postMessage({ src: 'wix-social-login', type, action: 'revoke' }, '*')
  }

  private _onOauthSuccess(
    dialog: Dialog,
    postMessageTarget: any,
    type: string,
    oAuthLoginCallbackData: any
  ) {
    const payload = oAuthLoginCallbackData.payload
    const siteMember = payload.siteMemberDto
    const smSession = payload.smSession

    if (oAuthLoginCallbackData.httpOnlySession) {
      this._handleSmSession(dialog, oAuthLoginCallbackData)
    } else if (smSession) {
      this._handleSmSession(dialog, { payload: smSession })
    } else {
      this._onApplyRegisterSuccess(dialog.props.language, { payload: siteMember })
    }

    this._revokeAppPermissions(postMessageTarget, type)
  }

  private _onOauthError(dialog: Dialog, postMessageTarget: any, type: string, errorCode: string) {
    dialog.endLoader()
    dialog.setOuathErrorMessageByCode(errorCode)
    this._revokeAppPermissions(postMessageTarget, type)
  }

  private _onServerAuthError(dialog: Dialog, errorCode: any) {
    dialog.endLoader()
    dialog.setErrorMessageByCode(errorCode)
  }

  getEnterPasswordSumitHandler(): EnterPasswordSubmitCallback {
    return (data: any, dialog: Dialog) => {
      const onError = (rawError?: any) => {
        let errorCode = 'SMForm_Error_General_Err'
        try {
          errorCode = JSON.parse(rawError.responseText).errorCode
        } catch (e) {
          /* do nothing */
        }
        dialog.setErrorMessageByCode(errorCode)
      }
      if (_.isEmpty(data.password)) {
        return dialog.setErrorMessage('PasswordLogin_Wrong_Password')
      }
      const nextPageInfo = this.state.nextPageInfo
      if (this._isPageProtectedByPasswordOnServer(nextPageInfo.pageId)) {
        this._validatePasswordOnServer(
          data.password,
          nextPageInfo,
          (response: any) => {
            nextPageInfo.jsonUrls = response.payload.urls
            this._closeDialogAndNavigateToPage(dialog, nextPageInfo)
          },
          onError
        )
      } else {
        this._validatePasswordAgainstJSON(dialog, data.password, onError)
      }
    }
  }

  getResetPasswordRequestSubmitHandler(): ResetPasswordRequestSubmitCallback {
    return (submitData: any, dialog: Dialog) => {
      const currentPageUrl = this.runtime.getCurrentUrl().full
      this.runtime.BI.reportClickedDialogSubmitButton({ context: dialog.props.id })

      this.backendService.sendForgotPasswordMail(
        {
          email: submitData.email,
          homePageUrl: currentPageUrl,
          lang: dialog.props.language
        },
        () => {
          const successCb = this.state.dialogProcessSuccessCallback
          if (this.state.invokeResetPasswordDialogCb) {
            successCb()
            this.state.dialogProcessSuccessCallback = null
            this.state.invokeResetPasswordDialogCb = null
          }
          this.state.showNotification(NOTIFICATION_KEY.ResetPasswordEmail, dialog.props.language)
        },
        dialog.setErrorMessageByCode
      )
    }
  }

  getSignupSubmitHandler(): SignupSubmitCallback {
    return (registerData: any, dialog: Dialog) => {
      const membersRegistrationType = this.runtime.getSmCollectionType()
      const svSession = this.runtime.getCookie(VIEWER_COOKIES.SV_SESSION)
      if (membersRegistrationType === 'Open') {
        this.backendService.register(
          registerData,
          (registerResponseData: any) => this._handleSmSession(dialog, registerResponseData),
          (msg: any) => this._onServerAuthError(dialog, msg),
          svSession
        )
        dialog.startLoader()
      } else if (membersRegistrationType === 'ApplyForMembership') {
        this.backendService.apply(
          registerData,
          (registerResponseData: any) =>
            this._onApplyRegisterSuccess(dialog.props.language, registerResponseData),
          dialog.setErrorMessageByCode,
          svSession
        )
      }
      this.runtime.BI.reportClickedDialogSubmitButton({ context: dialog.props.id })
    }
  }

  getOnForgetYourPasswordClickHandler(): PureHandler {
    return () => {
      this.runtime.BI.reportClickedForgotPassword()
      this.state.showDialog({
        dialogType: DIALOG_KEY.ResetPasswordEmail
      })
    }
  }

  getOnSwitchDialogClickHandler(toDialog: DIALOG_KEY): PureHandler {
    return () => {
      this.state.showDialog({
        dialogType: toDialog
      })
    }
  }

  getTokenHandler(): OnTokenMessage {
    return (
      token: string,
      type: 'facebook' | 'google',
      postMessageTarget: EventTarget,
      dialog: Dialog
    ) => {
      const svSession = this.runtime.getCookie(VIEWER_COOKIES.SV_SESSION)
      const onSuccess = (data: any) => this._onOauthSuccess(dialog, postMessageTarget, type, data)
      const onError = (errorCode: string) =>
        this._onOauthError(dialog, postMessageTarget, type, errorCode)
      this.backendService.handleOauthToken(onSuccess, onError, {
        svSession,
        token,
        provider: type,
        visitorId: this.runtime.getBiVisitorId(),
        mode: dialog.props.id,
        lang: dialog.props.language,
        privacyStatus: dialog.getJoinCommunityStatus()
      })
      dialog.startLoader()
    }
  }

  getLoginSubmitHandler(): LoginSubmitCallback {
    return (loginData: any, dialog: Dialog) => {
      const svSession = this.runtime.getCookie(VIEWER_COOKIES.SV_SESSION)
      this.runtime.BI.reportClickedDialogSubmitButton({ context: dialog.props.id })
      this.backendService.login(
        loginData,
        (loginResponseData: any) => this._handleSmSession(dialog, loginResponseData),
        (msg: any) => this._onServerAuthError(dialog, msg),
        svSession
      )
      dialog.startLoader()
    }
  }

  getResetPasswordDialogHandler(language: string): ResetPasswordCallback {
    return (newPassword: string, dialog: Dialog) => {
      this.runtime.BI.reportClickedDialogSubmitButton({ context: dialog.props.id })
      this.backendService.resetMemberPassword(
        newPassword,
        (lang: string) => {
          // FIXME ⚠️ - this line is not really valid!!!
          // delete this.siteData.currentUrl.query.forgotPasswordToken
          this.state.showNotification(NOTIFICATION_KEY.ResetPasswordNewPassword, lang)
        },
        dialog.setErrorMessageByCode,
        this.runtime.getCookie(VIEWER_COOKIES.SV_SESSION)
      )
    }
  }

  getCloseDialogHandler() {
    return (
      dialog: Dialog,
      authSuccess: boolean,
      isUserAction?: boolean,
      nextPageInfo?: Santa.NextPageInfo
    ) => this._closeDialog(dialog, authSuccess, isUserAction, nextPageInfo)
  }

  getWelcomeSubmitHandler(): any {
    return async (_data: any, dialog: Dialog) => {
      dialog.closeDialog(false)
      const api = await this.runtime.getAppAPI({
        appDefId: APPS.MEMBER_AREA,
        workerId: this.runtime.getPrimaryPageId()
      })
      api.navigateToSection({
        appDefinitionId: APPS.MEMBER_INFO,
        sectionId: SECTION.MEMBER_INFO,
        memberId: '_signedInUser_'
      })
    }
  }

  getEmailVerificationResendLinkHandler(): ResendEmailCallback {
    return (biContext: string) => {
      this._resendEmailVerification(this.state.pendingMemberId)
      this.runtime.BI.reportClickedLink(biContext, 'resend confirmation email')
      this.state.showDialog({
        dialogType: DIALOG_KEY.SentConfirmationEmail
      })
    }
  }

  getSentConfirmationResendHandler(): ResendEmailCallback {
    return (biContext: string) => {
      this._resendEmailVerification(this.state.pendingMemberId)
      this.runtime.BI.reportClickedLink(biContext, 'resend confirmation email')
    }
  }

  getResetPasswordSuccessNotification(): PureHandler {
    return () => {
      this.runtime.BI.reportClickedDialogSubmitButton({
        context: 'resetPasswordSuccessNotification'
      })
      this.state.showDialog({
        dialogType: DIALOG_KEY.Login
      })
    }
  }

  getOnSwitchAccountHandler(): PureHandler {
    return () => {
      this.core.logout(this.state.languageToDisplay, _.noop, _.noop, null)
    }
  }
}

export default class SiteMembersAspectCore {
  readonly runtime: ViewerRuntime
  readonly aspectState: AspectState
  readonly backendService: SiteMembersBackendService
  readonly handlersFactory: HandlersFactory

  constructor(runtime: ViewerRuntime) {
    this.runtime = runtime
    this.aspectState = new AspectState(this.runtime)
    this.backendService = new SiteMembersBackendService(this.runtime)
    this.handlersFactory = new HandlersFactory(this)
  }

  private _getDialogToShowFirst(showLoginDialog?: boolean): DIALOG_KEY {
    if (this.runtime.isCustomSignupPageExists()) {
      return null
    }
    if (_.isBoolean(showLoginDialog)) {
      return showLoginDialog ? DIALOG_KEY.Login : DIALOG_KEY.SignUp
    }
    return this.runtime.getDialogToShowFirst()
  }

  reloadClientSpecMap(callback: () => void): void {
    this.runtime.reloadClientSpecMap(callback) // tslint:disable-line no-floating-promises
  }

  showAuthenticationDialog({
    appId,
    showLoginDialog,
    successCallback,
    language,
    cancelCallback,
    checkCommunityCheckbox
  }: ShowAuthenticationDialogOptions) {
    this.aspectState.initiatorAppId = appId
    const dialogType = this._getDialogToShowFirst(showLoginDialog)
    if (!dialogType && this.runtime.isCustomSignupPageExists()) {
      this.runtime.navigateToPage(this.runtime.getCustomSignupPageId())
      return
    }
    this.aspectState.showDialog({
      dialogType,
      successCallback,
      language,
      cancelCallback,
      checkCommunityCheckbox
    })
  }

  showResetPasswordDialog({
    language,
    successCallback,
    cancelCallback
  }: ShowResetPasswordDialogOptions) {
    this.aspectState.showDialog({
      dialogType: DIALOG_KEY.ResetPasswordEmail,
      successCallback,
      language,
      cancelCallback
    })
    if (_.isFunction(successCallback)) {
      this.aspectState.invokeResetPasswordDialogCallback()
    }
  }

  getCustomNoPermissionsPageId() {
    const customNoPermissionsPageId = this.runtime.getCustomNoPermissionsPageId()
    return this.runtime.isPageExists(customNoPermissionsPageId) ? customNoPermissionsPageId : null
  }

  shouldRenderCustomSignupPage(nextPageInfo?: Santa.NextPageInfo) {
    const getDialogToRender = () => {
      if (nextPageInfo) {
        // const pageId = nextPageInfo.pageId
        // const pageSecurity = pageSecurityData(this.siteData, pageId)
        // return pageSecurityDialog.call(this, this.siteData, pageSecurity, pageId)
        return
      }
      return this._getDialogToShowFirst()
    }

    return (
      this.runtime.getCustomSignupPageId() &&
      this.runtime.isCustomSignupPageExists() &&
      getDialogToRender() === DIALOG_KEY.SignUp
    )
  }

  getComponentsToRender() {
    if (this.shouldRenderCustomSignupPage()) {
      return null
    }
    const { dialogToDisplay } = this.aspectState
    if (!dialogToDisplay) {
      return null
    }
    const propsFactory = new PropsFactory(this.runtime, this.aspectState, this.handlersFactory)
    this.runtime.downloadAllComps()
    return [
      {
        structure: getCurrentDialogComponentStructure(this.runtime, dialogToDisplay),
        props: propsFactory.getCurrentDialogComponentProps(dialogToDisplay)
      }
    ]
  }

  isLoggedIn(): boolean {
    return Boolean(this.aspectState.smtoken)
  }

  forceCloseDialog(): any {
    this.aspectState.forceStopShowDialog()
  }

  logout(
    language: string,
    onSuccess: () => void,
    onError: (msg: string) => void,
    options: LogoutOptions
  ): void {
    const _onSuccess = _.isFunction(onSuccess) ? onSuccess : _.noop
    const _onError = _.isFunction(onError) ? onError : _.noop
    const _options = _.isObject(options) ? options : ({} as LogoutOptions)
    const siteMember = this.runtime.siteMember
    if (
      this.runtime.getCookie(VIEWER_COOKIES.WIX_CLIENT) &&
      siteMember.details &&
      siteMember.details.owner
    ) {
      this.aspectState.showNotification(NOTIFICATION_KEY.SiteOwner, language)
      _onError('Current member is the site owner, which can not be logout')
    } else {
      this.aspectState.logoutState = true
      this.runtime.deleteCookie(
        VIEWER_COOKIES.SV_SESSION,
        this.runtime.getCurrentUrl().hostname,
        this.runtime.getMainPagePath()
      )
      if (this.runtime.getCookie(VIEWER_COOKIES.SM_SESSION)) {
        this.runtime.deleteCookie(
          VIEWER_COOKIES.SM_SESSION,
          this.runtime.getCurrentUrl().hostname,
          this.runtime.getMainPagePath()
        )
        this.reloadClientSpecMap(() => {
          this.aspectState.notificationToDisplay = null
          this.runtime.updateWixCodeModelDataAfterLogin() // tslint:disable-line no-floating-promises
          _onSuccess()
        })
      } else {
        const navigate = () =>
          _.isFunction(_options.navigateToPage)
            ? _options.navigateToPage()
            : this.runtime.reloadPage()
        this.backendService.logout(navigate, _onError)
      }
    }
  }
}

export const APPS = {
  MEMBER_AREA: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
  MEMBER_INFO: '14cffd81-5215-0a7f-22f8-074b0e2401fb'
}

export const VIEWER_COOKIES = {
  SV_SESSION: 'svSession',
  SM_SESSION: 'smSession',
  WIX_CLIENT: 'wixClient',
  SM_EF: 'sm_ef'
}

export const SECTION = {
  MEMBER_INFO: 'member_info'
}

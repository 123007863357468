import { SiteMemersAspect } from './site-members-aspect'
import { ExternalLibs, Constants } from './viewer-runtime'

const createAspect = ({
  type,
  runtimeData,
  externals,
  constants
}: {
  type: 'SITE_MEMBERS'
  runtimeData: {
    aspectSiteAPI: Santa.AspectSiteAPI
    experiment: Santa.Experiment
    privateMembers: Santa.PrivateMembers
    onLoginSuccessCallbacks: Santa.OnLoginSuccessCallbacks
    downloadCompsUtils: Santa.DownloadCompsUtils
  }
  externals: ExternalLibs
  constants: Constants
}) => {
  if (type !== 'SITE_MEMBERS') {
    throw new Error(`Aspect '${type}' is not supported!`)
  }
  return new SiteMemersAspect(runtimeData, externals, constants)
}

export { createAspect }

import * as _ from 'lodash'
import { DIALOG_KEY, DEFAULT_DIALOG_LANG, AUTH_DIALOGS } from '../../../constants/dialogs'
import ViewerRuntime from '../../../viewer-runtime'
import {
  LoginDialogProps,
  DialogBaseProps,
  SignupDialogProps,
  ResetPasswordRequestDialogProps,
  NotificationDialogProps,
  EnterPasswordDialogProps,
  EmailVerificationDialogProps,
  ResetPasswordDialogProps,
  SentConfirmationEmailDialogProps,
  WelcomeDialogProps,
  NoPermissionsToPageDialogsProps,
  Dialog
} from '../dialogs-types'
import { APPS, VIEWER_COOKIES } from '../../../constants/misc'
import { IAspectState, IHandlersFactory } from '../../aspect-types'

export default class PropsFactory {
  private readonly runtime: ViewerRuntime
  private readonly state: IAspectState
  private readonly handlersFactory: IHandlersFactory

  constructor(runtime: ViewerRuntime, state: IAspectState, handlersFactory: IHandlersFactory) {
    this.runtime = runtime
    this.state = state
    this.handlersFactory = handlersFactory
  }

  private _getLoginDialogProps(): LoginDialogProps {
    const props = {} as LoginDialogProps
    props.onSubmitCallback = this.handlersFactory.getLoginSubmitHandler()
    props.onTokenMessage = this.handlersFactory.getTokenHandler()
    props.onSwitchDialogLinkClick = this.handlersFactory.getOnSwitchDialogClickHandler(
      DIALOG_KEY.SignUp
    )
    props.onForgetYourPasswordClick = this.handlersFactory.getOnForgetYourPasswordClickHandler()
    props.needLoginMessage = this.state.showDialogMessage
    return props
  }

  private _getHasSocialAppInstalled(): () => Promise<boolean> {
    return async () => {
      try {
        const api = await this.runtime.getAppAPI({
          appDefId: APPS.MEMBER_AREA,
          workerId: this.runtime.getPrimaryPageId()
        })
        return new Promise<boolean>((resolve, reject) => {
          api.hasSocialPages(
            (hasSocialPages: boolean) => resolve(hasSocialPages),
            (err: any) => reject(err)
          )
        })
      } catch (e) {
        return false
      }
    }
  }

  private _getSignupDialogProps(): SignupDialogProps {
    const props = {} as SignupDialogProps
    props.onSubmitCallback = this.handlersFactory.getSignupSubmitHandler()
    props.onTokenMessage = this.handlersFactory.getTokenHandler()
    props.onSwitchDialogLinkClick = this.handlersFactory.getOnSwitchDialogClickHandler(
      DIALOG_KEY.Login
    )
    props.needLoginMessage = this.state.showDialogMessage
    props.hasSocialAppInstalled = this._getHasSocialAppInstalled()
    return props
  }

  private _getResetPasswordRequestProps(): ResetPasswordRequestDialogProps {
    return {
      onSubmitCallback: this.handlersFactory.getResetPasswordRequestSubmitHandler()
    } as ResetPasswordRequestDialogProps
  }

  private _addEmailVerificationProps() {
    const props = {} as EmailVerificationDialogProps
    props.biContext = 'confirm email to start'
    props.onResendLinkClick = this.handlersFactory.getEmailVerificationResendLinkHandler()
    props.onDialogReady = () => {
      this.runtime.BI.reportNotificationOpen(props.biContext)
    }
    return props
  }

  private _addSentConfirmationEmailProps() {
    const props = {} as SentConfirmationEmailDialogProps
    props.biContext = 'sent confirmation email'
    props.onResendLinkClick = this.handlersFactory.getSentConfirmationResendHandler()
    props.onDialogReady = () => {
      this.runtime.BI.reportNotificationOpen(props.biContext)
    }
    return props
  }

  private _getNotificationProps(): NotificationDialogProps {
    const t = (key: string) => this.runtime.translationService(key, this.state.languageToDisplay)
    const props = {} as NotificationDialogProps
    switch (this.state.notificationToDisplay) {
      case DIALOG_KEY.SignUp:
        const siteMember = this.runtime.siteMember
        const nameToDisplay = siteMember.details.email
        props.title = ''
        props.description = `${t('SMApply_Success1')} ${t('SMApply_Success2').replace(
          '{0}',
          nameToDisplay
        )}`
        props.buttonText = t('SMContainer_OK')
        break
      case DIALOG_KEY.ResetPasswordEmail:
        props.title = t('siteMembersTranslations_RESET_PASSWORD_CHECKEMAIL_TITLE')
        props.description = t('siteMembersTranslations_RESET_PASSWORD_CHECKEMAIL_TEXT')
        props.buttonText = t('SMContainer_OK')
        break
      case DIALOG_KEY.ResetPasswordNewPassword:
        props.title = t('siteMembersTranslations_Reset_Password_Sucess_Title')
        props.description = ''
        props.buttonText = t('SMContainer_OK')
        props.onButtonClick = this.handlersFactory.getResetPasswordSuccessNotification()
        break
      case 'siteowner':
        props.title = t('SITEMEMBERMANGAGER_OWNER_LOGOUT_ACTION_TITLE')
        props.description = t('SITEMEMBERMANGAGER_OWNER_LOGOUT_ACTION_MESSAGE')
        props.buttonText = t('SMContainer_OK')
        break
      default:
    }
    return props
  }

  private _getEnterPasswordProps(): EnterPasswordDialogProps {
    return {
      onSubmitCallback: this.handlersFactory.getEnterPasswordSumitHandler()
    } as EnterPasswordDialogProps
  }

  private _addWelcomeProps(baseProps: DialogBaseProps): WelcomeDialogProps {
    const props = {} as WelcomeDialogProps
    props.onSubmitCallback = this.handlersFactory.getWelcomeSubmitHandler()
    const smCookie = this.runtime.getCookie(VIEWER_COOKIES.SM_EF)
    const smCookieLang = smCookie && smCookie.split('|')[1]
    props.language = (smCookieLang && smCookieLang.trim()) || DEFAULT_DIALOG_LANG // eslint-disable-line no-mixed-operators
    props.onCloseDialogCallback = (dialog: Dialog, authSuccess: boolean, isUserAction: boolean) => {
      baseProps.onCloseDialogCallback(dialog, authSuccess, isUserAction)
      const mainPagePath = this.runtime.getMainPagePath()
      this.runtime.deleteCookie(
        VIEWER_COOKIES.SM_EF,
        this.runtime.getCurrentUrl().hostname,
        mainPagePath
      )
      this.runtime.deleteCookie(VIEWER_COOKIES.SM_EF, this.runtime.getCurrentUrl().hostname, '/')
    }
    return props
  }

  private _addNoPermissionsToPageProps(): NoPermissionsToPageDialogsProps {
    const props = {} as NoPermissionsToPageDialogsProps
    props.onSwitchAccountClick = this.handlersFactory.getOnSwitchAccountHandler
    return props
  }

  private _getDialogProps(baseProps: DialogBaseProps): DialogBaseProps {
    switch (this.state.dialogToDisplay) {
      case DIALOG_KEY.Login:
        return this._getLoginDialogProps()
      case DIALOG_KEY.SignUp:
        return this._getSignupDialogProps()
      case DIALOG_KEY.ResetPasswordEmail:
        return this._getResetPasswordRequestProps()
      case DIALOG_KEY.Notification:
        return this._getNotificationProps()
      case DIALOG_KEY.PasswordProtected:
        return this._getEnterPasswordProps()
      case DIALOG_KEY.EmailVerification:
        return this._addEmailVerificationProps()
      case DIALOG_KEY.SentConfirmationEmail:
        return this._addSentConfirmationEmailProps()
      case DIALOG_KEY.Welcome:
        return this._addWelcomeProps(baseProps)
      case DIALOG_KEY.NoPermissionsToPage:
        return this._addNoPermissionsToPageProps()
      default:
        return null
    }
  }

  private _getCurrentDialogProps(): DialogBaseProps {
    const baseProps = {
      language: this.state.languageToDisplay,
      onCloseDialogCallback: this.handlersFactory.getCloseDialogHandler(),
      notClosable: this.state.notClosable,
      checkCommunityCheckbox: this.state.checkCommunityCheckbox,
      smCollectionExposure: this.runtime.getSmCollectionExposure()
    } as DialogBaseProps
    return _.merge(baseProps, this._getDialogProps(baseProps))
  }

  private _getResetPasswordDialogProps() {
    const props = {} as ResetPasswordDialogProps
    const { notClosable } = this.state
    props.language = _.get(this.runtime.getCurrentUrl().query, 'lang', DEFAULT_DIALOG_LANG)
    props.onSubmitCallback = this.handlersFactory.getResetPasswordDialogHandler(props.language)
    props.notClosable = notClosable
    return props
  }

  getCurrentDialogComponentProps(dialogToDisplay: DIALOG_KEY) {
    if (_.includes(AUTH_DIALOGS, dialogToDisplay)) {
      this.runtime.BI.reportAuthDialogOpened(dialogToDisplay, this.runtime.isSocialLoginEnabled())
    }
    if (dialogToDisplay === DIALOG_KEY.ResetPasswordNewPassword) {
      return this._getResetPasswordDialogProps()
    }
    return this._getCurrentDialogProps()
  }
}

import * as _ from 'lodash'
import ViewerRuntime from '../viewer-runtime/'
import { PRIVACY_STATUS } from '../constants/dialogs'

interface InteralRequestParams {
  type: 'GET' | 'POST'
  url: string
  asForm: boolean
  data?: any
  isPlatformizedEndpoint?: boolean
  headers?: any[]
}

interface RequestParams {
  type: 'GET' | 'POST'
  uri: string
  asForm: boolean
  data?: any
  // isPlatformizedEndpoint?: boolean
  // headers?: any[]
  svSession?: string
  initiator?: string
}

type InteralRequestOnSuccess = (response: any) => void

type InteralRequestOnError = (errorCode: string) => void

class SiteMembersBackendService {
  private readonly msid: string
  private readonly verifyTokenUrl: string
  private readonly logoutUrl: string
  private readonly resendEmailVerificationUrl: string
  private readonly appUrl: string
  // private readonly customRegisterUrl: string
  // private readonly authorizedPagesUrl: string
  private readonly collectionId: string
  private readonly baseUrl: string
  private readonly viewerAjax: (ajaxObj: any) => void

  private readonly PATH = {
    login: '/api/member/login',
    register: '/api/member/register',
    customRegister: '/api/wix-sm/v1/auth/register',
    apply: '/api/member/apply',
    sendForgotPasswordMail: '/api/member/sendForgotPasswordMail',
    resetMemberPassword: '/api/member/changePasswordWithMailToken',
    getMemberDetails: '/api/member',
    handleOauthToken: '/api/social/token/handle',
    verify: '/_api/wix-sm/verify',
    authorize: '/api/wix-sm/v1/authorize',
    logout: '/_api/wix-sm/logout',
    resendEmailVerification: '/_api/wix-sm/email/resend'
  }

  constructor(runtime: ViewerRuntime) {
    this.msid = runtime.getMetaSiteId()
    const siteId = runtime.getSiteId()
    // const publicBaseUrl = runtime.getPublicBaseUrl()
    const currentUrl = runtime.getCurrentUrl()
    this.verifyTokenUrl = `//${currentUrl.host}${this.PATH.verify}/${this.msid}/${siteId}`
    this.logoutUrl = `//${currentUrl.host}${this.PATH.logout}/${this.msid}`
    this.resendEmailVerificationUrl = `//${currentUrl.host}${this.PATH.resendEmailVerification}`
    this.viewerAjax = (ajaxObj: any) => runtime.ajax(ajaxObj)
    this.collectionId = runtime.getSmCollectionId()
    this.appUrl = currentUrl.full
    // this.customRegisterUrl = `https://${currentUrl.host}${this.PATH.customRegister}`
    // this.authorizedPagesUrl = `${publicBaseUrl}${this.PATH.authorize}/${siteId}/pages`
    this.baseUrl = runtime.serviceTopology.siteMembersUrl
  }

  private _extractSignedToken(payload: any) {
    const val =
      _.get(payload, 'sessionToken') || _.get(payload, ['smSession', 'sessionToken']) || ''
    return _.get(val.match(/^JWS\.(.+)/), 0)
  }

  private _handleResponse(
    onSuccess: InteralRequestOnSuccess,
    onError: InteralRequestOnError,
    data: any,
    isPlatformizedEndpoint: boolean
  ) {
    if (_.get(data, 'errorCode')) {
      onError(data.errorCode)
      return
    }
    let _data = data
    if (isPlatformizedEndpoint) {
      _data = {
        payload: _.merge(
          {},
          {
            sessionToken: _.get(data, ['session', 'token']),
            status: _.get(data, ['member', 'status']),
            siteMemberDto: {
              id: _.get(data, ['member', 'id'])
            },
            raw: _.cloneDeep(data)
          }
        )
      }
    }
    const signedToken = this._extractSignedToken(_data.payload)
    if (signedToken) {
      this._sendVerifyTokenRequest(signedToken, onSuccess, onError, _data.payload)
    } else {
      onSuccess(_data)
    }
  }

  private _sendVerifyTokenRequest(
    signedToken: string,
    onSuccess: InteralRequestOnSuccess,
    onError: InteralRequestOnError,
    previousPayload: any
  ): any {
    const onSuccessWrapper = function(response: any) {
      const mergedResponses = _.merge({}, response, {
        httpOnlySession: true,
        payload: previousPayload
      })
      onSuccess(mergedResponses)
    }
    this._sendSiteMembersRequestInternal(
      {
        type: 'POST',
        url: this.verifyTokenUrl,
        data: { token: signedToken },
        asForm: true
      },
      onSuccessWrapper,
      onError
    )
  }

  // reqPath, data, type, onSuccess, onError, svSession, initiator, previewMode
  private _sendSiteMembersRequest(
    params: RequestParams,
    onSuccess: InteralRequestOnSuccess,
    onError: InteralRequestOnError,
    previewMode?: boolean
  ) {
    const { data, svSession, initiator, type, uri } = params
    const body = _.merge(data || {}, {
      collectionId: this.collectionId,
      metaSiteId: this.msid
    })
    if (svSession) {
      //optional parameters
      _.merge(body, { svSession, appUrl: this.appUrl })
      if (initiator) {
        //login with initiator is only done when svsession is available
        body.initiator = initiator
      }
    }
    this._sendSiteMembersRequestInternal(
      {
        type,
        url: this.baseUrl + uri,
        data: body,
        asForm: true
      },
      onSuccess,
      onError,
      previewMode
    )
  }

  private _sendSiteMemberTokenRequest(
    body: any,
    onSuccess: InteralRequestOnSuccess,
    onError: InteralRequestOnError
  ) {
    const query = `?collectionId=${this.collectionId}&metaSiteId=${this.msid}`
    this._sendSiteMembersRequestInternal(
      {
        type: 'POST',
        url: this.baseUrl + this.PATH.handleOauthToken + query,
        data: body,
        asForm: false
      },
      onSuccess,
      onError
    )
  }

  private _sendSiteMembersRequestInternal(
    params: InteralRequestParams,
    onSuccess: InteralRequestOnSuccess,
    onError: InteralRequestOnError,
    previewMode?: boolean
  ) {
    const { type, url, data, asForm, isPlatformizedEndpoint, headers } = params
    let ajaxObj = {
      type,
      url,
      data,
      asForm,
      dataType: 'json',
      jsonp: false,
      success: (responseData: any) => {
        this._handleResponse(onSuccess, onError, responseData, isPlatformizedEndpoint)
      },
      error: onError
    }
    if (headers) {
      _.assign(ajaxObj, { headers })
    }
    if (previewMode === true) {
      ajaxObj = _.merge(ajaxObj, {
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      })
    }
    this.viewerAjax(ajaxObj)
  }

  resendEmailVerification(
    pendingMemberId: string,
    onSuccess: InteralRequestOnSuccess,
    onError: InteralRequestOnError
  ) {
    this._sendSiteMembersRequestInternal(
      { type: 'GET', url: `${this.resendEmailVerificationUrl}/${pendingMemberId}`, asForm: false },
      onSuccess,
      onError
    )
  }

  logout(onSuccess: () => void, onError: (msg: string) => void): void {
    this._sendSiteMembersRequestInternal(
      { type: 'POST', url: this.logoutUrl, asForm: true },
      onSuccess,
      onError
    )
  }

  sendForgotPasswordMail(
    data: { email: string; homePageUrl: string; lang: string },
    onSuccess: () => void,
    onError: (errorCode: string) => void
  ): void {
    this._sendSiteMembersRequest(
      {
        type: 'POST',
        uri: this.PATH.sendForgotPasswordMail,
        data: {
          email: data.email,
          returnUrl: data.homePageUrl,
          lang: data.lang
        },
        asForm: true
      },
      onSuccess,
      onError
    )
  }

  register(
    data: { email: string; password: string; privacyStatus: string },
    onSuccess: (registerResponseData: any) => void,
    onError: (errorCode: any) => void,
    svSession: string,
    initiator?: string
  ) {
    this._sendSiteMembersRequest(
      {
        uri: this.PATH.register,
        data,
        svSession,
        initiator,
        type: 'POST',
        asForm: true
      },
      onSuccess,
      onError
    )
  }

  login(
    data: any,
    onSuccess: (loginResponseData: any) => void,
    onError: (msg: any) => void,
    svSession: string,
    initiator?: string
  ): any {
    this._sendSiteMembersRequest(
      {
        uri: this.PATH.login,
        data,
        svSession,
        initiator,
        type: 'POST',
        asForm: true
      },
      onSuccess,
      onError
    )
  }

  apply(
    data: { email: string; password: string; privacyStatus: string },
    onSuccess: (registerResponseData: any) => void,
    onError: (errorCode: string) => void,
    svSession: string,
    initiator?: string
  ) {
    this._sendSiteMembersRequest(
      {
        uri: this.PATH.apply,
        data,
        svSession,
        initiator,
        type: 'POST',
        asForm: true
      },
      onSuccess,
      onError
    )
  }

  handleOauthToken(
    onSuccess: (data: any) => void,
    onError: (errorCode: string) => void,
    body: {
      svSession: string
      token: string
      provider: 'facebook' | 'google'
      visitorId: string
      mode: string
      lang: string
      privacyStatus: PRIVACY_STATUS
    }
  ): any {
    this._sendSiteMemberTokenRequest(body, onSuccess, onError)
  }

  resetMemberPassword(
    newPassword: string,
    onSuccess: (data: any) => void,
    onError: (errorCode: string) => void,
    svSession: string
  ): void {
    this._sendSiteMembersRequest(
      {
        uri: this.PATH.resetMemberPassword,
        data: newPassword,
        svSession,
        type: 'POST',
        asForm: true
      },
      onSuccess,
      onError
    )
  }
}

export default SiteMembersBackendService

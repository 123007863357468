/// <reference path="../santa.d.ts" />
import SiteMemersAspectCore from './core'
import ViewerRuntime, { ExternalLibs, SiteState, Constants } from '../viewer-runtime'
import {
  ShowAuthenticationDialogOptions,
  ShowResetPasswordDialogOptions,
  LogoutOptions
} from './dto'

export class SiteMemersAspect {
  private readonly runtime: ViewerRuntime
  private readonly core: SiteMemersAspectCore

  constructor(siteState: SiteState, externals: ExternalLibs, constants: Constants) {
    this.runtime = new ViewerRuntime({ ...siteState, externals, constants })
    this.core = new SiteMemersAspectCore(this.runtime)
  }

  showAuthenticationDialog(options: ShowAuthenticationDialogOptions) {
    this.core.showAuthenticationDialog(options)
  }

  isLoggedIn(): boolean {
    return this.core.isLoggedIn()
  }

  getCustomSignupPageId(): string {
    return this.runtime.getCustomSignupPageId()
  }

  isCustomSignupPageExists(): boolean {
    return this.runtime.isCustomSignupPageExists()
  }

  showResetPasswordDialog(options: ShowResetPasswordDialogOptions) {
    return this.core.showResetPasswordDialog(options)
  }

  forceCloseDialog(): void {
    this.core.forceCloseDialog()
  }

  getCustomNoPermissionsPageId(): string {
    return this.core.getCustomNoPermissionsPageId()
  }

  shouldRenderCustomSignupPage(nextPageInfo: Santa.NextPageInfo) {
    return this.core.shouldRenderCustomSignupPage(nextPageInfo)
  }

  getComponentsToRender(): {
    structure: Santa.ComponentStructure
    props: Object
  }[] {
    return this.core.getComponentsToRender()
  }

  logout(language: string, onSuccess: () => void, onError: () => void, options: LogoutOptions) {
    this.core.logout(language, onSuccess, onError, options)
  }
}

import * as _ from 'lodash'
import { DIALOG_KEY } from '../constants/dialogs'
// function reportBiAuthDialogOpened(dialogToDisplay: DIALOG_KEY, isSocialLoginEnabled: boolean) {
//   const params = _.merge(
//     {
//       context: dialogToDisplay,
//       has_social: isSocialLoginEnabled /*!!isSocialLoginEnabled(this.siteData)*/
//     },
//     { initator: 'unknown' }
//   )
//   reportSmBi(events.SITE_MEMBER_AUTH_DIALOG_OPENED, params)
// }

// function reportSmBi(event: BI.EVENT, params: Object = {}) {
//   const visitorId = aspectSiteApi.getSiteAPI().getBiVisitorId()
//   if (visitorId) {
//     // 'visitorId' is empty in preview mode
//     aspectSiteApi.reportBI(event, _.merge(params, { visitor_id: visitorId }))
//   }
// }

export class BI {
  private readonly _events: any
  private readonly _visitorId: string
  private readonly _reportBI: (event: Object, params: Object) => void

  constructor(
    events: any,
    { visitorId, reportBI }: { visitorId: string; reportBI(event: Object, params: Object): void }
  ) {
    this._events = events
    this._visitorId = visitorId
    this._reportBI = (event: Object, params: Object) => reportBI(event, params)
  }

  private _reportSmBi(event: Object, params: Object = {}) {
    if (this._visitorId) {
      // 'visitorId' is empty in preview mode
      this._reportBI(event, _.merge(params, { visitor_id: this._visitorId }))
    }
  }

  reportClickedForgotPassword(): void {
    return this._reportSmBi(this._events.SITE_MEMBER_CLICKED_FORGOT_PASSWORD)
  }

  reportClickedDialogSubmitButton(params: { context: string }): void {
    return this._reportSmBi(this._events.SITE_MEMBER_SUBMIT_BUTTON, params)
  }

  reportAuthDialogOpened(dialogToDisplay: DIALOG_KEY, isSocialLoginEnabled: boolean): void {
    const params = _.merge(
      {
        context: dialogToDisplay,
        has_social: isSocialLoginEnabled /*!!isSocialLoginEnabled(this.siteData)*/
      },
      { initator: 'unknown' }
    )
    this._reportSmBi(this._events.SITE_MEMBER_AUTH_DIALOG_OPENED, params)
  }

  reportClickedLink(context: string, name: string): void {
    this._reportSmBi(this._events.SITE_MEMBER_CLICK_LINK, {
      context,
      name
    })
  }

  reportNotificationOpen(notificationName: string): void {
    this._reportSmBi(this._events.SITE_MEMBER_NOTIFICATION_OPEN, {
      notification_name: notificationName
    })
  }

  reportExitDialog({ context }: { context: string }): void {
    this._reportSmBi(this._events.SITE_MEMBER_EXIT_DIALOG, { context })
  }
}
